jQuery(function ($) {
    
  'use strict';


    $('.each-member').click(function(){
        $('.each-member figure').removeClass('active');
        $('figure',$(this)).addClass('active');
    });

    
  /**
   * =====================================
   * Function for windows height and width      
   * =====================================
   */
  function windowSize( el ) {
    var result = 0;
      if("height" == el)
          result = window.innerHeight ? window.innerHeight : $(window).height();
      if("width" == el)
        result = window.innerWidth ? window.innerWidth : $(window).width();

      return result; 
  }
 
 
  /**
   * =====================================
   * Function for windows height and width      
   * =====================================
   */
  function deviceControll() {
    if( windowSize( 'width' ) < 768 ) {
      $('body').removeClass('desktop').removeClass('tablet').addClass('mobile');
    }
    else if( windowSize( 'width' ) < 992 ){
      $('body').removeClass('mobile').removeClass('desktop').addClass('tablet');
    }
    else {
      $('body').removeClass('mobile').removeClass('tablet').addClass('desktop');
    }
  }



  /**
   * =====================================
   * Function for email address validation         
   * =====================================
   */
  function isValidEmail(emailAddress) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
  };



  /**
   * =======================================
   * Function: Home Section Fullscreen View.
   * =======================================
   */
  var fullscreen_home = function() {

    if( $('.section-separator-js').length ) {
      var top = ( windowSize('height') - $('.section-separator-js').height() ) / 2;

      if( top > 80 ) {
        $('.section-separator-js').css({
          'padding-top':      top + "px", 
          'padding-bottom':   top + "px",
          'position':         'relative',
        })
      }else {
        $('.section-separator-js').css({
          'padding-top':      80 + "px", 
          'padding-bottom':   80 + "px",
          'position':         'relative',
        })
      }
    }
  }

  var offsetTopCus = function(e) {
    if( windowSize('width') > 991) {
      $('.offset-top-js').each(function() {
        $(this).css({
          'margin-top': $('.position-fix').height()+10
        })
      });
    }else {
      $('.offset-top-js').css({
        'margin-top': '0px'
      })
    }
  }




  $(window).on('resize', function() {

    fullscreen_home();
    deviceControll();
    offsetTopCus();

  });


  $(document).on('ready', function() {

    fullscreen_home();
    deviceControll();
    offsetTopCus();


    /**
     * =======================================
     * Mobile navigation style
     * =======================================
     */
    $('.navbar-toggle').on('click', function() {

      $(this).toggleClass('active');
      $( $(this).attr('data-action') ).toggleClass('active');
      $('#content-body').toggleClass('active');

    });


    

    /**
     * =======================================
     * COLLAPSE SECTION
     * =======================================
     */
    var faq = $('.faq-group');

    faq.find('.each-faq').each(function() {
      if( !$(this).hasClass('open') ) {
        $(this).find('.faq-content').hide();
      }
    });

    faq.find('.faq-trigger').on('click', function(el) {
      el.preventDefault();

      var openFaq = $(this).closest('.each-faq');

      openFaq.toggleClass('open').find('.faq-content').slideToggle( 300 );
      openFaq.siblings('.each-faq:visible').each(function() {
        $(this).removeClass('open').find('.faq-content').slideUp( 300 );
      });

    });



  });



});

